<template>
<img
    :src="`/images/ratings/${rating}-big.png`" :alt="`${rating} ${ageRating}`" class="ratings-icon"
>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ratingsRegionMapJson from '$components/ratings/ratingsRegionMap.json'
import { Geolocate } from '$services/geolocate'

export default defineComponent({
    data() {
        return {
            rating: 'ESRB', // default to ESRB
            windowWidth: window?.innerWidth
        }
    },

    computed: {
        ageRating() {
            switch (this.rating) {
            case 'ACB':
                return '15+'
            case 'RARS':
                return '18+'
            case 'USK':
            case 'PEGI':
            case 'ClassInd':
                return '16+'
            default: return 'M 17+'
            }
        }
    },

    mounted() {
        window.addEventListener('resize', this.onResize)
        const countryCode = Geolocate.getCountryCode()
        Object.entries(ratingsRegionMapJson).forEach((rating) => {
            if (rating[1].includes(countryCode)) {
                this.rating = rating[0]
            }
        })
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize)
    },

    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.ratings-icon {
    position: absolute;
}
</style>
